import ContactForm from "@/components/contactForm";
import BaseLink from "@/components/generic/baseLink";
import Header from "@/components/generic/header";
import Wrapper from "@/components/generic/wrapper";
import Layout from "@/components/layout";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { PageProps } from "gatsby";
import React from "react";

const SupportPage: React.FC<PageProps> = () => (
  <Layout title="Support">
    <Container maxWidth="lg">
      <Header>Support</Header>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", lg: "row" },
          alignItems: { xs: "center", lg: "flex-start" },
          justifyContent: "center",
          gap: { xs: 3, lg: 1 },
        }}
      >
        <ContactForm />

        <Wrapper sx={{ pb: 6, maxHeight: 220, width: { xs: "100%", md: 480 } }}>
          <Typography variant="h4" component="p" color="primary" align="center" gutterBottom>
            Contact
          </Typography>
          <div>
            <Typography component="span">e-mail: &emsp;&emsp; </Typography>
            <BaseLink href="mailto:marco@mddd.nl">marco@mddd.nl</BaseLink>
          </div>
          <div>
            <Typography component="span">telefoon: &emsp; </Typography>
            <BaseLink href="tel:+31619861019">06 - 19 86 10 19</BaseLink>
          </div>
        </Wrapper>
      </Box>
    </Container>
  </Layout>
);

export default SupportPage;
